
<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
    
      <LoadingVue v-if="itemload" />
        <div class="row g-2" v-else>
            <div class="col-md-8 mx-auto">
                    <Notification />
                <div class="row">
    
                    <div class="col-md-4">
                        <div>
    
                            <div class="row">
                                <div class="col-12" v-for="(item, index) in items" :key="item.id">
    
    
    
                                    <div v-if="index  <  itemlist" class="card" :class="{active: addclass(item)}" @click="selectedelct(item)">
    
                                        <div class="card-body p-1">
                                            <div class="form-check custom-option custom-option-basic checked">
                                                <label class="form-check-label custom-option-content" :for="item.code">
                      
                        <img :src="link+item.image" alt="" class="rounded-circle avatar-sm  me-2">
                        <span class="custom-option-body">
                          <strong class="fs-5">{{item.name}}</strong>
                        </span>
                      </label>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                                <div v-if="(items.length > itemlist) || (more == true)" @click="viewmore" class="d-flex justify-content-center mb-3">
                                    <b-button variant="primary" size="sm">{{more ? "less": "more"}}</b-button>
                                </div>
                            </div>
    
    
                            <!--end row-->
    
                            <!--end offcanvas-->
                        </div>
                    </div>
    
    
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Electricity</h4>
    
                                    </div>
                                    <!-- end card header -->
                                    <div class="card-body">
                                        <div class="mb-3 hstack gap-2 flex-wrap">
    
                                            <div class="form-check form-switch form-switch-lg mb-1" dir="ltr">
                                                <input type="radio" name="type" class="form-check-input" value="prepaid" id="prepaid" v-model="type">
                                                <label class="form-check-label" for="prepaid">Prepaid</label>
                                            </div>
                                            <div class="form-check form-switch form-switch-lg" dir="ltr">
                                                <input type="radio" name="type" class="form-check-input" value="postpaid" id="postpaid" v-model="type">
                                                <label class="form-check-label" for="postpaid">Postpaid</label>
                                            </div>
                                        </div>
                                        <div class="live-preview">
                                            <form action="javascript:void(0);">
                                                <div class="mb-3">
                                                    <label for="meternumber" class="form-label">Meter Number</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="meternumber"
                        
                        v-model="meternumber"
                        placeholder="Enter meter number"
                      />
                    </div>
                    <div class="mb-3" v-if="verify">
                      <label for="employeeUrl" class="form-label"
                        >Meter name</label
                      >
                      <input
                        type="url"
                        class="form-control"
                        id="employeeUrl"
                        v-model="metername"
                        readonly
                        placeholder="Name"
                      />
                    </div>
                  <div class="mb-3" v-if="verify">
                      <label for="employeeUrl" class="form-label"
                        >Sub Amount</label
                      >
                      <input
                        type="number"
                        v-model="amount"
                        class="form-control"
                        id="employeeUrl"
                        placeholder="Sub Amount"
                      />
                    </div>
                  
                    <div class="text-end">
                      <button type="submit" @click="submit" class="btn btn-primary" :disabled="load"> 
                          <div class="spinner-border spinner-border-sm" role="status" v-if="load" > 
  <span class="sr-only">Loading...</span>
</div> Continue
                 
                      </button>
                    </div>
                  </form>
                </div>
              
              </div>
            </div>
          </div>
       
        </div>
                    </div>
                    </div>
                </div>
                <!-- end col -->
            </div>
            <!--end row-->
        
        
        </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";

export default {
    page: {
        title: "Team",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            title: "Electricity",
            link: process.env.VUE_APP_URL,
            selecteditem: null,
            type: null,
            load: false,
            itemload: false,
            meternumber: null,
            amount: null,
            metername: null,
            itemlist: 3,
            verify: false,
            more: false,
            items: [],

        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
        LoadingVue,
    },
    created() {
        this.getitems();
    },
    watch: {

        selecteditem: function() {


            this.verify = false;
            this.metername = null;
        },
           meternumber: function() {


            this.verify = false;
            this.metername = null;
        },
    type: function() {


            this.verify = false;
            this.metername = null;
        }



    },



    methods: {



             async processtransaction() {
       this.load = true;

    await axios.post(process.env.VUE_APP_MAIN_URL + "/process/electricity", { type: this.type, meternumber: this.meternumber, selecteditem: this.selecteditem.id, amount: this.amount }).catch((error) => {

              this.load = false;

            if (error.response.status == 420) {
               Swal.fire("Error!", error.response.data.data, "error");
            }else {
                  Swal.fire("Error!", "Please make sure you have active internet or fields are correct", "error");
            }

               
                throw error;

            }).then((response) => {
                this.load = false;
                 this.$router.push({name: "transaction", params: { hash: response.data.data.data.hash}});

            })

      },



        submit(){


    if(this.verify == true){

     this.processtransaction();

    }else {

    this.verifymeter();


    }
},


        viewmore() {

            if (this.more == false) {
                this.more = true;
                this.itemlist = 100

            } else {

                this.more = false;
                this.itemlist = 3

            }





        },


        async verifymeter() {
            this.load = true;
            await axios.post(process.env.VUE_APP_MAIN_URL + "/verify/electricity", { type: this.type, meternumber: this.meternumber, selecteditem: this.selecteditem.id, }).catch((error) => {
                if (error.response.status == 420) {
                    Swal.fire("Error!", error.response.data.data, "error");
                    // console.log(error.response.data)
                } else {
                    Swal.fire("Error!", "Check your internet connection", "error");
                }

                this.load = false;
                throw error;

            }).then((response) => {

                if (response.data.status == true) {
                    console.log(response.data.data.plans);

                    this.metername = response.data.data.name;
                 
                    this.verify = true;


                }
                this.load = false;

            })
        },



        selectedelct(item) {
            this.selecteditem = item;
        },
        addclass(item) {


            return item == this.selecteditem;
        },


        async getitems() {
            this.itemload = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/electricity").catch((error) => {
    this.itemload = false;

                Swal.fire("Error!", "Please Check Your Internet Connection", "error");
                throw error;

            }).then((response) => {
    this.itemload = false;
                if (response.data.status == true) {

                    this.items = response.data.data.data;


                }

            })
        },

    },
};
</script>

<style scoped>
.active {
    border: 2px solid green;
}
</style>